import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Donate`}</h1>
    <p><strong parentName="p">{`Your generous donation helps us continue building the DesignInquiry Universe: residencies, scholarships and publications.`}</strong></p>
    <p><a href="https://paypal.me/DesignInquiry?country.x=US&locale.x=en_US" className="button">Donate</a></p>
    <p>{`Special thanks to major support from:`}</p>
    <p><strong parentName="p">{`MF Foundation | Concordia University Office of the Vice-President, Research & Graduate Studies | Social Sciences and Humanities Research Council University of Lethbridge | Universität der Künste Berlin (University of the Arts Berlin) | Wayne State University | Museum of Contemporary Art Detroit`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      